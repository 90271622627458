@import url('./css/fonts_css2.css');
@import url('./css/styles.css');

@import '~antd/dist/antd.less';
@import './partials/variable.less';
@import './partials/customAntd.less';
@import './partials/core.less';

@import './partials/dashboard.less';
@import './partials/auth.less';
@import './partials/navigation.less';
@import './partials/header.less';
@import './partials/sidePanel.less';
@import './partials/collapseBox.less';
@import './partials/dataTable.less';
@import './partials/erp.less';
@import './partials/form.less';

@root-entry-name: default;@primary-color: #1B98F5;@link-color: #1B98F5;@border-radius-base: 4px;@heading-color: #10002b;@text-color: #2D3142;@text-color-secondary: #4F5D75;@disabled-color: #BFC0C0;@background-color-light: rgba(249, 250, 252,1);@background-color-base: rgba(249, 250, 252,0.7);@btn-font-weight: 600;